.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.source-list {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}

.chip {
  /* background-color: #e0e0e0; */
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 20px;
}

.scroll-button {
  cursor: pointer;
  /* background-color: #e0e0e0; */
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Analitics */
.summary-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.summary-text {
  text-align: center;
}
.summary-desc {
  font-size: 0.875rem !important;
}
.summary-val {
  font-size: 2.5rem;
}
.search-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.date-range-selector {
  display: flex;
  gap: 20px;
  font-size: 16px;
  margin-block: 10px;
  align-items: center;
}
.date-range-item {
  cursor: pointer;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}
.date-range-item:hover {
  color: #00bfff;
  text-decoration: underline;
}
.date-range-item.active {
  color: #00bfff;
  text-decoration: underline;
}
.pagination-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chevron-left,
.chevron-right {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.chevron-left:hover,
.chevron-right:hover {
  transform: scale(1.2);
}

.page-number {
  color: white;
  font-size: 16px;
  margin: 0 10px;
}

.source-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.event-select {
  font-size: medium;
  width: 100%;
  max-width: 40%;
}


.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 16px;
}

.chip {
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.chip:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.src-sel {
  background-color: rgba(255, 255, 255, 0.8);
  color: #333333;
  border-radius: 15px;
  border: 2px solid #333333;
}