/* Estilos del modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background: var(--background);
  border-radius: 0.5rem;
  padding: 1rem;
  max-width: 90%;
  max-height: 90%;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeButton {
  position: absolute;
  top: 0.5rem;
  right: -1.5rem;
  background: none;
  border: none;
  color: var(--contrast);
  font-size: 1.5rem;
  cursor: pointer;
}

.fullImage {
  max-width: 100%;
  max-height: 90vh;
  border-radius: 0.5rem;
}

/* Miniatura clickeable */
.thumbnail {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.thumbnail:hover {
  transform: scale(1.1); /* Animación sutil al pasar el ratón */
}
