:root {
  height: 100%;
  --pico-primary: #efae42 !important;
  --pico-primary-hover: color-mix(
    in oklab,
    var(--pico-primary),
    black 10%
  ) !important;
  --pico-primary-background: var(--pico-primary) !important;
  --pico-primary-hover-background: color-mix(
    in oklab,
    var(--pico-primary),
    black 10%
  ) !important;
  --pico-primary-focus: color-mix(
    in oklab,
    var(--pico-secondary),
    transparent 75%
  ) !important;
  --pico-secondary: #255743 !important;
  --pico-table-border-color: color-mix(
    in oklab,
    var(--pico-secondary),
    transparent 50%
  ) !important;
}

body {
  overflow-x: hidden;
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  header {
    flex-shrink: 0;
  }
  main {
    flex-grow: 1;
  }
  footer {
    flex-shrink: 0;
  }
}

button.sm {
  padding: calc(var(--pico-form-element-spacing-vertical) / 2)
    calc(var(--pico-form-element-spacing-horizontal) / 2);
}

/* Json View */
button.jv-button {
  padding: 0;
  background-color: transparent;
  border: none;
  line-height: 1;
}
